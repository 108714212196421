import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

function Details({ open, n, handleClose }) {
	return (<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
		<React.Fragment>
			<DialogTitle id="simple-dialog-title">Help</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					Scorri la mappa per trovare i nostri furgoncini dei gelati.<br/>
					Clicca su un furgoncino per avere maggiori informazioni o per contattarci!
					<br/><br/>
					<strong>
						Al momento
						{n === 0 && <span> non ci sono furgoncini </span>}
						{n === 1 && <span> c'è un furgoncino </span>}
						{n > 1 && <span> ci sono {n} furgoncini </span>}
						in giro
					</strong>
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Chiudi
				</Button>
			</DialogActions>
		</React.Fragment>
	</Dialog>)
}

export default Details;
