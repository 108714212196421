import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function Details({ open, data, handleClose }) {
	return (<Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
		{data && <React.Fragment>
			<DialogTitle id="simple-dialog-title">{data.name}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">
					{data.model}<br/>
					<a href={`tel:${data.phone}`}>{data.phone}</a><br/>
					{data.contact}
				</DialogContentText>
			</DialogContent>
			<Alert severity="info">Posizione rilevata {data.lastUpdateDiff} minuti fa</Alert>
			<DialogActions>
				<Button onClick={handleClose} color="primary">
					Chiudi
				</Button>
			</DialogActions>
		</React.Fragment>}
	</Dialog>)
}

export default Details;
