import React, { useEffect, useState } from 'react';
import MapGL, { Marker } from 'react-map-gl'
import { Icon, Tooltip } from '@material-ui/core'

function MyMap({ userPosition, devices, handleViewDetails }) {
	const [userFirstPosition, setUserFirstPosition] = useState(null)
	const [viewport, setViewport] = useState({
		latitude: 46.084332,
		longitude: 12.183235,
		zoom: 13,
		width: '100%',
		height: '100%',
	});

	// const usePrevious = (value) => {
	// 	const ref = useRef();
	// 	useEffect(() => {
	// 	  ref.current = value;
	// 	});
	// 	return ref.current;
	// }

	useEffect(() => {
		if (userPosition.latitude !== undefined && userPosition.longitude !== undefined && !userFirstPosition) {
			setUserFirstPosition({
				userPosition
			})
			setViewport(v => ({
				...viewport,
				latitude: userPosition.latitude,
				longitude: userPosition.longitude,
			}))
		}
	}, [userPosition])

	return (<div style={{height: '100%'}}>
		<MapGL
			mapStyle="mapbox://styles/mapbox/streets-v10"
			mapboxApiAccessToken={'pk.eyJ1IjoicGFydGl0YXRyaXBsYSIsImEiOiJjazhsaWZheDMwN284M2xwODk0OGZoNXAyIn0.oIK4vXsONt853oAEgFPAog'}
			onViewportChange={setViewport}
			{...viewport}
		>
			{devices.map((device, d) => {
				return (<Marker key={d} latitude={Number(device.position ? device.position.latitude : 0)} longitude={Number(device.position ? device.position.longitude : 0)}>
					<Tooltip title={device.name} aria-label="Fiorindo">
						<Icon style={{ fontSize: 40, color: device.color }} onClick={() => handleViewDetails(device)}>
							local_shipping
						</Icon>
					</Tooltip>
				</Marker>)
			})}
		</MapGL>
	</div>)
}

export default MyMap;
