import React, { useState, useEffect } from 'react'
import { AppBar, Badge, Icon, IconButton, Toolbar, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import moment from 'moment'

import MyMap from './components/Map'
import http from 'axios'
import {usePosition} from './components/UsePosition';
import Details from './components/Details';
import Help from './components/Help';

const traccar_user = 'api_fiorindo'
const traccar_pass = 'e2NZ2wgn2LYS!WtJ'
// Test API
// const traccar_user = 'api_user'
// const traccar_pass = 'api_pass'

const styles = theme => ({
	title: {
		flexGrow: 1,
		textAlign: 'center',
	},
})

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#df0209',
		},
		secondary: {
			main: '#eeda01',
		},
	},
	typography: { useNextVariants: true },
})

function App({ classes }) {
	const {latitude, longitude/* , error */} = usePosition();
	// const [position, setPosition] = useState({});
	const [devices, setDevices] = useState([]);
	const [details, setDetails] = useState({
		open: false,
		data: null,
	});
	const [help, setHelp] = useState({
		open: false,
	});
	const activeDevices = devices.map(device => {
		const lastUpdateDiff = moment().diff(moment(device.lastUpdate), 'minutes')
		// Se il dispositivo non è offline
		if (device.status !== 'offline') {
			if (lastUpdateDiff <= 10) {
				// Se sono passati meno di 10 minuti dall'utlimo rilevamento
				return {
					...device,
					lastUpdateDiff: lastUpdateDiff,
					color: 'green',
				}
			} else if (lastUpdateDiff <= 30) {
				// Se sono passati meno di 30 minuti dall'utlimo rilevamento
				return {
					...device,
					lastUpdateDiff: lastUpdateDiff,
					color: 'darkorange',
				}
			} else if (lastUpdateDiff <= 60) {
				// Se sono passati meno di 60 minuti dall'utlimo rilevamento
				return {
					...device,
					lastUpdateDiff: lastUpdateDiff,
					color: 'red',
				}
			} else {
				// Se sono passati più di 60 minuti tolgo il furgoncino
				return null
			}
		} else {
			// Se è offline tolgo il furgoncino
			return null
		}
	}).filter(n => !!n)

	useEffect(() => {
		getPosition()
	}, [])

	const getPosition = async () => {
		const loadDevices = await http.get('https://gps.partitatripla.it/api/devices',
		{
			headers: {
				'Accept': 'application/json',
				'Authorization': 'Basic ' + btoa(`${traccar_user}:${traccar_pass}`)
			}
		})
		let devices = loadDevices.data
		let positions = [];
		let promises = [];
		devices.forEach(async (device) => {
			promises.push(http.get(`https://gps.partitatripla.it/api/positions?id=${device.positionId}`,
				{
					headers: {
						'Accept': 'application/json',
						'Authorization': 'Basic ' + btoa(`${traccar_user}:${traccar_pass}`)
					}
				})
			)
		})
		Promise.all(promises).then(responses => {
			responses.forEach(response => {
				positions.push(response.data[0])
			})
			devices.forEach(async (device) => {
				device.position = positions.filter(p => p.id === device.positionId)[0]
			})
			setDevices([...devices])
		}, reason => {
			console.warn(reason)
		});
		setTimeout(() => getPosition(), 30 * 1000); // Richiamo l'aggiornamento dopo 30 secondi
	}

	const handleDetailsToggle = (data) => {
		setDetails({
			open: !!data ? true : false,
			data: data,
		})
	}

	const handleHelpToggle = (openState) => {
		setHelp({
			open: openState,
		})
	}

	return (<MuiThemeProvider theme={theme}>
		<div className="App" style={{width: '100%', height: '100%'}}>
			<AppBar position="fixed">
				<Toolbar color="primary">
					<div className={classes.title}><Typography variant="subtitle1" color="inherit" className={classes.titleP}>Gelati da Fiorindo</Typography></div>
					<IconButton
						onClick={() => handleHelpToggle(true)}
						color="inherit"
					>
						<Badge badgeContent={activeDevices.length} color="secondary">
							<Icon>
								help_outline
							</Icon>
						</Badge>
					</IconButton>
				</Toolbar>
			</AppBar>
			<MyMap
				userPosition={{latitude: latitude, longitude: longitude}}
				devices={activeDevices}
				handleViewDetails={handleDetailsToggle}
			/>
			<Details
				open={details.open}
				data={details.data}
				handleClose={() => handleDetailsToggle(false)}
			/>
			<Help
				open={help.open}
				n={activeDevices.length}
				handleClose={() => handleHelpToggle(false)}
			/>
		</div>
	</MuiThemeProvider>);
}

export default withStyles(styles)(App);
